import { storage } from './config';

export const uploadFile = (file, path, fileName) => {
  const ref = storage.ref(`${path}/${fileName || file.name}`);
  return ref.put(file).then(snapshot => snapshot.ref.getDownloadURL());
};

export const uploadData = (data, path) => {
  const ref = storage.ref(path);
  return ref
    .putString(data, 'data_url')
    .then(snapshot => snapshot.ref.getDownloadURL());
};
