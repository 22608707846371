import React from 'react';
import { Block } from 'components/atoms';
import get from 'lodash/get';
import { Profile } from 'components/molecules';
import EditProfile from 'components/forms/EditProfile';
import ChangePassword from 'components/forms/ChangePassword';
import { Modal } from 'components/molecules';
import Swal from 'sweetalert2';
// import PhoneNumberForm from '../../forms/FormItems/PhoneNumberForm';
import { PhoneNumberForm, OtpForm } from 'components/forms/FormItems';
import firebase from 'appFirebase/config';
import PhoneModal from 'components/organisms/PhoneModal';

// REDUX
import { selectAuthData } from 'modules/auth/selectors';
import { connect } from 'react-redux';
import { getProfile } from 'modules/auth/actions';
import { bindActionCreators } from 'redux';

// HOC
import withTheme from 'hocs/withTheme';

// CloudApi
import CloudApi from 'api/cloudApi';

class PersonalInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      editProfile: false,
      changePassword: false,
      addPhoneNumber: false,
      otpModal: false,
      otp: null,
      error: false,
      errorMessage: null,
      phoneNumber: null,
      confirmationResult: null,
      appVerifier: null,
      sendOtpPending: false,
      countDownKey: null,
      sendSmsPending: false
    };
    this.modal = Swal.mixin({
      title:
        '<h2 style="font-family: thai-sans-neue; font-weight: 600; margin:0"}>Success!</h2>',
      type: 'success',
      confirmButtonColor: '#f89202',
      confirmButtonText:
        '<p style="font-family: thai-sans-neue; font-size: 24px; margin: 0;">OK</p>'
    });
  }

  handleSubmit = ({
    otpModal,
    addPhoneNumber,
    confirmationResult,
    phoneNumber,
    appVerifier
  }) => {
    this.setState({
      otpModal,
      addPhoneNumber,
      confirmationResult,
      phoneNumber,
      appVerifier
    });
  };

  onChange = otp => {
    this.setState({ otp });
  };

  onClear = () => {
    this.setState({
      otp: null
    });
  };

  handleOTP = () => {
    const { profile, getProfile } = this.props;
    const { otp, confirmationResult } = this.state;
    this.setState({ sendOtpPending: true });
    return confirmationResult
      .confirm(otp)
      .then(data => {
        return firebase
          .auth()
          .signInWithCustomToken(get(profile, 'customTokens', null))
          .then(() => {
            return CloudApi.loginByPhoneNumber({
              uid: data.user.uid,
              phoneNumber: data.user.phoneNumber.replace('+66', '0'),
              phoneNumberUid: get(profile, 'phoneNumberUid', null)
            }).then(() => {
              getProfile();
              return this.setState({
                otpModal: false,
                otp: null
              });
            });
          });
      })
      .catch(({ code, message }) => {
        const errorMessage = {
          'auth/invalid-verification-code':
            'The SMS verification code used is invalid'
        };
        this.setState({
          errorMessage: get(errorMessage, code, message)
        });
      })
      .finally(() =>
        this.setState({
          sendOtpPending: false
        })
      );
  };

  handleResend = () => {
    const { phoneNumber } = this.state;
    firebase.auth().languageCode = 'en';
    window.recaptchaVerifier.render().then(function(widgetId) {
      window.grecaptcha.reset(widgetId);
    });
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'resend-button',
      {
        size: 'invisible',
        callback: function(response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log(response);
        }
      }
    );
    var appVerifier = window.recaptchaVerifier;
    this.setState({ sendSmsPending: true });
    return (
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        // .then(data => console.log('data', data));
        .then(confirmationResult => {
          console.log('Sms sent');
          console.log(confirmationResult);
          this.setState({ confirmationResult, countDownKey: Date.now() });
        })
        .catch(error => {
          if (error.code === 'auth/too-many-requests') {
            this.setState({
              errorMessage:
                'We have blocked all requests from this device due to unusual activity. Try again later.'
            });
          } else
            this.setState({
              errorMessage: 'Something went wrong, Please try again'
            });
        })
        .finally(() => this.setState({ sendSmsPending: false }))
    );
  };

  render() {
    const { editProfile, changePassword, addPhoneNumber } = this.state;
    const { profile, getProfile } = this.props;
    const { birthday, email, displayName, photoURL, uid, gender } = profile;
    const userPhoneNumber = get(profile, 'phoneNumber', null);
    return (
      <Block.Flex justifyContent={'center'}>
        {editProfile ? (
          <EditProfile
            birthday={birthday}
            email={email}
            displayName={displayName}
            photoURL={photoURL}
            goBack={() => this.setState({ editProfile: false })}
            uid={uid}
            getProfile={getProfile}
            modal={this.modal}
            gender={gender}
          />
        ) : changePassword ? (
          <ChangePassword
            goBack={() => this.setState({ changePassword: false })}
            getProfile={getProfile}
            modal={this.modal}
          />
        ) : (
          <Block>
            <Profile
              birthday={birthday}
              email={email}
              displayName={displayName}
              photoURL={photoURL}
              editProfile={() => this.setState({ editProfile: true })}
              changePassword={() => this.setState({ changePassword: true })}
              addPhoneNumber={() => this.setState({ addPhoneNumber: true })}
              phoneNumber={userPhoneNumber}
              gender={gender}
            />
            <PhoneModal
              open={addPhoneNumber}
              onClose={() => {
                this.setState({ addPhoneNumber: false });
              }}
              profile={profile}
              inMyAccount
            />
          </Block>
        )}
      </Block.Flex>
    );
  }
}

const mapStateToProps = state => ({
  profile: selectAuthData(state)
});

export default connect(mapStateToProps, dispatch =>
  bindActionCreators(
    {
      getProfile
    },
    dispatch
  )
)(withTheme()(PersonalInfo));
