import React, { Component } from 'react';
import { Header, NotificationPage, Footer } from 'components/molecules';
import PersonalInfo from 'components/organisms/PersonalInfo/PersonalInfo';
import { Block, Text } from 'components/atoms';
import styled from '@emotion/styled';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MyCardsPage from '../pages/MyCardsPage';
import CreditCard from '@material-ui/icons/CreditCard';
import Notifications from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
// HOC
import newWithProfile from 'hocs/newWithProfile';
import withTheme from 'hocs/withTheme';

const StyledBlock = styled('div')({
  '.title': {
    fontWeight: 'bold',
    color: '#2a2a2a'
  },
  '.menu': {
    marginTop: '40px', //t r b l
    borderBottom: '2px solid #e6e6e6'
  },
  '.menubottom': {
    marginTop: '40px', //t r b l
    backgroundColor: 'white'
  }
});

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  selectCategory = value => {
    this.setState({ value: value });
  };

  renderTextButton = category => {
    let value = 0;
    if (category === 'My Card') value = 0;
    else if (category === 'My Coupon') value = 1;
    else value = 2;

    return (
      <Block.Flex
        height={'100%'}
        css={{
          cursor: 'pointer',
          ':hover > *': {
            color: '#f89202 !important'
          }
        }}
        onClick={() => this.selectCategory(value)}>
        <Text
          color={this.state.value === value ? '#f89202 !important' : 'grey'}
          style={{
            padding: '10px',
            borderBottom: this.state.value === value && '2px solid #f89202',
            position: 'relative',
            top: '2px'
          }}
          mx={[12, 12, 16, 24]}
          fontSize={[20, 25, 30]}
          fontWeight={500}>
          {category}
        </Text>
      </Block.Flex>
    );
  };

  renderList = () => {
    const { value } = this.state;
    const { profile } = this.props;
    if (value == 0) {
      return <MyCardsPage profile={profile} />;
    }
    if (value == 1) {
      return <NotificationPage profile={profile} />;
    }
    return <PersonalInfo profile={profile} />;
  };

  render() {
    return (
      <StyledBlock>
        <Header />
        <Block maxWidth={1200} mx={'auto'} pb={[80, 0]}>
          <Text
            fontSize={[35, 45]}
            className="title"
            m={['20px 0px 0px 25px', '100px 0px 0px 25px']}
            display={['none', 'block']}>
            MyAccount
          </Text>

          <Block className="menu" display={['none', 'flex']}>
            {this.renderTextButton('My Card')}
            {this.renderTextButton('My Coupon')}
            {this.renderTextButton('Personal Info')}
          </Block>
          <Block mt={'30px'}>{this.renderList()}</Block>
        </Block>
        <Block mt={[0, 120]}>
          <Footer />
        </Block>
        <Block
          display={['flex', 'none']}
          background={'white'}
          alignItems={'flex-end'}>
          <BottomNavigation
            style={{
              width: '100%',
              position: 'fixed',
              bottom: 0,
              boxShadow: '0px -2px 10px 0px rgba(192,192,192,0.8)'
            }}
            value={this.state.value}
            onChange={(event, newValue) => {
              this.setState({ value: newValue });
            }}
            showLabels>
            <BottomNavigationAction label="My Card" icon={<CreditCard />} />
            <BottomNavigationAction
              label="My Coupon"
              icon={<Notifications />}
            />
            <BottomNavigationAction
              label="Personal Info"
              icon={<PersonIcon />}
            />
          </BottomNavigation>
        </Block>
      </StyledBlock>
    );
  }
}

export default withTheme()(newWithProfile(MyAccount));
